<template>
  <svg v-bind="$attrs">
    <rect
      v-if="rect"
      :x="rect.x"
      :y="rect.y"
      :width="rect.width"
      :height="rect.height"
      :fill="rect.fill"
    />
    <use :xlink:href="`${url}#${id}`"></use>
  </svg>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "custom-icons",
    },
    rect: {
      type: Object,
      default: null,
    },
  },
  computed: {
    url() {
      // No icon support for SSR mode (will code if needed)

      return typeof window === "undefined" ? null : window.MIX[`/icons/${this.type}.svg`];
    },
  },
};
</script>
