import { sprintf } from "sprintf-js";
export default {
  methods: {
    __(key) {
      if (window.translations === undefined) {
        return key;
      }
      var translation = window.translations[key];
      if (translation === undefined) {
        return key;
      }
      return translation;
    },
    sprintf(...args) {
      return sprintf(...args);
    },
  },
};
