<template>
  <div class="tailwind">
    <FadeIn>
      <div
        v-if="show"
        class="#fixed #z-[10000] #top-0 #left-0 #w-full #h-full #bg-inkdropdark/90 #flex #min-h-0 #justify-center #items-center #p-[40px_15px] sm:#p-[60px]"
      >
        <svg-use
          id="xmark"
          type="solid"
          class="#w-12 #h-12 #z-[1] sm:#w-16 sm:#h-16 #fill-white #top-[30px] #right-[30px] #absolute #cursor-pointer hover:#opacity-50 #transition-all"
          @click="() => closeLightbox()"
        ></svg-use>
        <div
          ref="imgContainer"
          v-outside-click="handleOutsideClick"
          class="#relative"
          :class="{ '#h-full #w-full': !imgRef }"
        >
          <div
            v-show="loading"
            class="#absolute #w-full #h-full #flex #min-h-0 #justify-center #items-center"
          >
            <svg-use
              id="circle-notch"
              type="solid"
              class="#animate-spin #h-16 #w-16 #fill-[#d5d5d5] #box-content"
            />
          </div>
          <div
            v-show="imgRef && !loading"
            class="#absolute #w-full #h-full #top-0 #left-0 #flex #min-h-0 #justify-between"
          >
            <div
              class="#h-full #w-1/2 #flex #min-h-0 #justify-start #items-center #pl-4 md:#pl-8 #transition-all"
              :class="
                firstImg
                  ? '#opacity-0'
                  : '#group #cursor-pointer #opacity-100 md:#opacity-0 hover:#opacity-100 #bg-gradient-to-r #from-[#24212b8c]'
              "
              @click="prevImg"
            >
              <svg-use
                id="angle-left"
                type="solid"
                class="#fill-white #w-12 #h-12 md:#w-16 md:#h-16 #opacity-100 md:#opacity-0 #transition-all group-hover:#opacity-100"
              ></svg-use>
            </div>
            <div
              class="#h-full #w-1/2 #flex #min-h-0 #justify-end #items-center #pr-4 md:#pr-8 #right-0 #transition-all"
              :class="
                lastImg
                  ? '#opacity-0'
                  : '#group #cursor-pointer #opacity-100 md:#opacity-0 hover:#opacity-100 #bg-gradient-to-l #from-[#24212b8c]'
              "
              @click="nextImg"
            >
              <svg-use
                id="angle-right"
                type="solid"
                class="#fill-white #w-12 #h-12 md:#w-16 md:#h-16 #opacity-100 md:#opacity-0 #transition-all group-hover:#opacity-100"
              ></svg-use>
            </div>
          </div>
          <p v-if="imgRef" class="#absolute #-bottom-12 #left-0 #text-white #text-xs #opacity-80">
            {{ __("Afbeelding") }} {{ imgIndex + 1 }} {{ __("van") }}
            {{ images.length }}
          </p>
        </div>
      </div>
    </FadeIn>
  </div>
</template>

<script>
import SvgUse from "../components/SvgUse.vue";
import outsideClick from "../directives/outsideClick.js";
import FadeIn from "../components/FadeIn.vue";
import lang from "../mixins/lang.js";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapMutations } = createNamespacedHelpers("lightbox");

export default {
  components: {
    SvgUse,
    FadeIn,
  },
  directives: {
    outsideClick,
  },
  mixins: [lang],
  data() {
    return {
      loading: false,
      imgRef: null,
    };
  },
  computed: {
    ...mapState(["show", "currentImg", "imgIndex", "images"]),
    lastImg() {
      return this.imgIndex === this.images.length - 1;
    },
    firstImg() {
      return this.imgIndex === 0;
    },
  },
  watch: {
    imgIndex() {
      this.loading = true;
    },
    show(newValue) {
      if (newValue === true) {
        if (this.imgRef) {
          this.imgRef.remove();
          this.imgRef = null;
        }
        this.loading = true;
        this.getImage();
      }
    },
  },
  created() {
    this.emitter.on("open-lightbox-legacy", (event) => {
      let images = [];
      document
        .querySelectorAll("[data-lightbox='" + event.currentTarget.dataset.lightbox + "']")
        .forEach((elem) => {
          images.push(elem.href);
        });
      this.openLightbox({ img: event.currentTarget.href, images: images });
    });

    document.body.addEventListener("keydown", this.handleKeyDown);
  },
  beforeUnmount() {
    document.body.removeEventListener("keydown", this.handleKeyDown);
    this.emitter.off("open-lightbox-legacy");
  },
  methods: {
    ...mapMutations(["openLightbox", "closeLightbox", "lightboxNextPage", "lightboxPreviousPage"]),
    getImage() {
      if (this.imgRef) {
        this.imgRef.style.opacity = "0";
      }
      let img = new Image();
      img.decoding = "async";
      img.src = this.images[this.imgIndex];
      img.classList.add(
        "#object-contain",
        "fade-in-image",
        "#max-h-[80vh]",
        "#max-w-[85vw]",
        "#rounded-[6px]",
        "#overflow-hidden",
        "#border-4",
        "#border-white",
      );

      img.decode().then(() => {
        if (this.imgRef) {
          this.imgRef.remove();
        }
        this.imgRef = img;
        if (this.$refs.imgContainer) {
          this.$refs.imgContainer.appendChild(img);
        }
        this.loading = false;
      });
    },
    nextImg() {
      if (this.lastImg) {
        return;
      }

      this.loading = true;
      this.lightboxNextPage();
      this.getImage();
    },
    prevImg() {
      if (this.firstImg) {
        return;
      }

      this.loading = true;
      this.lightboxPreviousPage();
      this.getImage();
    },
    handleOutsideClick() {
      if (this.show === false) {
        return;
      }

      this.closeLightbox();
    },
    handleKeyDown(event) {
      if (this.show === false) {
        return;
      }
      if (event.key === "ArrowLeft") {
        this.prevImg();
      }
      if (event.key === "ArrowRight") {
        this.nextImg();
      }
    },
  },
};
</script>

<style>
.fade-in-image {
  animation: fadeIn 300ms ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
