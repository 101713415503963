export default {
  namespaced: true,
  state: () => ({
    show: false,
    currentImg: null,
    imgIndex: 0,
    images: [],
  }),
  mutations: {
    openLightbox(state, data) {
      state.images = data.images.map((img) => img.replace(/ /g, "%20"));
      state.currentImg = data.img.replace(/ /g, "%20");

      state.imgIndex = state.images.indexOf(state.currentImg);
      state.show = true;
    },
    closeLightbox(state) {
      state.show = false;
    },
    lightboxNextPage(state) {
      state.imgIndex++;
    },
    lightboxPreviousPage(state) {
      state.imgIndex--;
    },
  },
};
